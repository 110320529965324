import React from 'react';
import { string, func, bool } from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import {
  Box, Button, Typography,
} from '@material-ui/core';
import { GatsbyImage } from 'gatsby-plugin-image';

import Loading from 'components/loading';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';

import { imageStyles } from './styles';

const ALT = 'Total Loyalty explainer video';

const VideoPromoImage = ({
  action = () => {},
  message = 'Total Loyalty explainer video',
  showSpinner = false,
}) => {
  const styles = imageStyles();

  const query = graphql`
  {
    file(relativePath: { eq: "promo-vid-image.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, formats: [AUTO,WEBP], placeholder:TRACED_SVG)
      }
    }
  }
`
  const data = useStaticQuery(query);

  const onImageClick = async () => {
    const buildDataLayer = await import('data-layer');
    const dataLayer = buildDataLayer.default();
    dataLayer.trackLanding({
      descriptor: 'play_explainer_video',
    });
    action();
  };

  return (
    <Box boxShadow={2} onClick={onImageClick} className={styles.pointer}>
        <GatsbyImage
          imgStyle={{ width: '100%', height: 'auto' }}
          image={data.file.childImageSharp.gatsbyImageData}
          alt={ALT}
          />
        {showSpinner ? (
          <>
          <div className={styles.leftCorner}>
            <Typography
              variant="h5"
              component="p"
              align="left"
              className={styles.videoMessage}>
              {ALT}
            </Typography>
          </div>
          <div className={styles.centered}>
            <Loading />
          </div>
          </>
        ) : (
          <>
          <div className={styles.leftCorner}>
            <Typography
              variant="h5"
              component="p"
              align="left"
              className={styles.videoMessage}>
              {ALT}
            </Typography>
          </div>
          <div className={styles.centered}>
            <Button
              color="primary"
              fullWidth
              variant="contained"
              onClick={onImageClick}
              className={styles.redbutton}
              aria-label={message}
              >
              Play
              <FontAwesomeIcon icon={faPlay} className={styles.submitIcon}/>
            </Button>
          </div>
          </>
        )}

    </Box>
  );
};

VideoPromoImage.propTypes = {
  action: func,
  message: string,
  showSpinner: bool,
};

export default VideoPromoImage;
